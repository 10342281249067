import React from "react";

const CatHolder = (props) => {
  const img = props.img;
  const name = props.name;
  let description = props.description;
  const summary = props.summary;
  if (description)
    description = description.substring(3, description.length - 4);
  return (
    <div className="snap-center snap-always border-2 rounded-lg shadow-lg bg-zinc-200 ">
      <div className="flex w-[350px]  ">
        <img src={img} alt="" className=" w-32 h-48 object-cover rounded-lg " />
        <div className="flex flex-col w-full break-all px-4">
          <span className="m-2 text-center text-l font-mono text-black break-all w-full">
            {name}
          </span>
          <hr  
            style={{
              color: '#000000',
              backgroundColor: '#000000',
              height: .5,
              borderColor : '#000000'
          }}/>
          <span className="text-justify break-all w-full mt-2">{summary}</span>
        </div>
      </div>
    </div>
  );
};

export default CatHolder;
